<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold">
        Contact Types
      </v-toolbar-title>
    </v-app-bar>

    <v-divider class="mt-3" />

    <div class="mt-12 mx-4 pa-xs-4 mx-sm-auto">
      <v-row>
        <v-col class="d-flex" cols="12" md="7">
          <v-spacer />

          <v-btn
            class="px-5"
            color="primary"
            @click="addOrUpdateTypeDialog = true"
          >
            <v-icon class="mr-3"> {{ icons.add }} </v-icon>
            New Contact Type
          </v-btn>
        </v-col>

        <v-col cols="12" md="7">
          <v-card dense>
            <v-card-text>
              <template v-for="(type, index) in types">
                <v-chip
                  class="my-2 mx-1 px-5 text-capitalize"
                  :key="index"
                  @click:close="removeItem(type)"
                  @click="updateItem(type)"
                  close
                  link
                  label
                >
                  <span class="mr-2"> {{ type.name }} </span>
                </v-chip>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="addOrUpdateTypeDialog" persistent max-width="390">
      <v-card>
        <v-card-title class="text-h5">
          <span v-if="selectedType.id">Update</span>
          <span v-else>Add New</span>
          <span class="ml-2"> Contact Type</span>
        </v-card-title>

        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-text-field
                v-model="typeForm.name"
                label="Contact Type Name"
                :error-messages="typeForm.$getError('name')"
                :hide-details="!typeForm.$hasError('name')"
                outlined
                flat
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pb-4 mx-2">
          <v-spacer />

          <v-btn color="error" @click="cancelAdd()" text> Cancel </v-btn>

          <v-btn color="primary" @click="addOrUpdateContactType()">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmModal v-model="confirmDialog" title="Remove Type">
      <template #message>
        Do you want to remove
        <span v-if="selectedType.name" class="font-weight-bold red--text">
          {{
            selectedType.name.replace(/(^|\s)\S/g, function (t) {
              return t.toUpperCase()
            })
          }}
        </span>
        ?
      </template>

      <v-btn :disabled="deleting" @click="cancelRemove()" text> Cancel </v-btn>
      <v-btn color="primary" :loading="deleting" @click="confirmRemove()" text>
        Confirm
      </v-btn>
    </ConfirmModal>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackbar.show = false">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ConfirmModal from '@/components/modals/ConfirmModal'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ContactType from '@/models/ContactType'
import ControlsMixin from '@/utils/mixins/Controls'
import Form from '@/utils/form'

import { find, sortBy, findIndex } from 'lodash'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'ContactTypes',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    ConfirmModal,
  },

  data() {
    return {
      addOrUpdateTypeDialog: false,
      addingOrUpdating: false,
      confirmDialog: false,
      deleting: false,

      snackbar: {
        show: false,
        message: null,
        color: '',
      },

      types: [],

      icons: {
        add: mdiPlus,
      },

      typeForm: new Form({
        id: null,
        name: '',
      }),

      selectedType: {
        id: null,
        name: null,
      },
    }
  },

  created() {
    this.fetchContactTypes()
  },

  methods: {
    async fetchContactTypes() {
      const { data } = await ContactType.get()

      this.types = data

      this.sortTypes()
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    updateItem(type) {
      this.selectedType = type
      this.addOrUpdateTypeDialog = true

      this.typeForm.name = type.name.replace(/(^|\s)\S/g, function (t) {
        return t.toUpperCase()
      })
      this.typeForm.id = type.id
    },

    removeItem(type) {
      this.selectedType = type
      this.confirmDialog = true
    },

    async addOrUpdateContactType() {
      this.addingOrUpdating = true
      try {
        const type = await new ContactType(this.typeForm).save()

        this.typeForm.$reset()

        this.addOrUpdateTypeDialog = false
        this.addingOrUpdating = false
        this.selectedType = { id: null, name: null }

        const _type = find(this.types, { id: type.id })

        if (_type) {
          Object.assign(_type, type)
          this.showSnackbar('Contact type updated!', 'success')
        } else {
          this.types.push(type)
          this.showSnackbar('Contact type added!', 'success')
        }

        this.sortTypes()
      } catch (err) {
        this.selectedType = { id: null, name: null }
        this.addingOrUpdating = false

        if (err.response.status === 422) {
          this.typeForm.$setErrors(err.response.data.errors)
        }
      }
    },

    async confirmRemove() {
      this.deleting = true

      try {
        await this.selectedType.delete()

        this.types.splice(
          findIndex(this.types, { id: this.selectedType.id }),
          1
        )

        this.confirmDialog = false
        this.deleting = false
        this.selectedType = { id: null, name: null }
        this.showSnackbar('Contact type removed!', 'success')
      } catch (e) {
        this.confirmDialog = false
        this.deleting = false
      }
    },

    sortTypes() {
      this.types = sortBy(this.types, ['name'])
    },

    cancelAdd() {
      this.selectedType = { id: null, name: null }
      this.addOrUpdateTypeDialog = false
      this.typeForm.$reset()
    },

    cancelRemove() {
      this.selectedType = { id: null, name: null }
      this.confirmDialog = false
    },
  },
}
</script>
